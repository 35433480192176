import React from 'react';
import '../styles/WelcomeSection.css';

const WelcomeSection = () => {
    return (
        <div className="welcome-section">
            <div className="welcome-text">
                <h1 className="headline">Bonjour et bienvenue!!</h1>
                <p className="description">
                    Where every cup of coffee is crafted from the finest beans to deliver rich, indulgent flavors and captivating aromas.
                    Whether you're savoring a quick espresso to jumpstart your day or enjoying a leisurely cappuccino with friends,
                    we are dedicated to providing a perfect blend that awakens your senses and delights your taste buds. Pair your
                    coffee with our exquisite menu, thoughtfully curated to offer a variety of dishes that complement every sip.
                    <br /><br />
                    From breakfast to dinner, experience a refined and welcoming dining atmosphere where quality, taste, 
                    and elegance come together for an unforgettable moment.
                </p>
            </div>
        </div>
    );
}

export default WelcomeSection;

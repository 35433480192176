import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import '../styles/ReviewsCarousel.css';

const ReviewsCarousel = () => {
  
  const reviews = [
    {
      // name: "John Doe",
      text: "The best café experience I’ve ever had! The coffee is rich and the food is exquisite.",
      image: "/assets/cs - 4.jpg",
    },
    {
      // name: "Jane Smith",
      text: "A perfect spot for a weekend brunch. The atmosphere is cozy, and the service is excellent!",
      image: "/assets/cs - 2.jpg",
    },
    // Add more reviews as needed
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
  };

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length);
  };

  // Handle swipe gestures
  const handlers = useSwipeable({
    onSwipedLeft: handleNextClick,
    onSwipedRight: handlePrevClick,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true // Optional: allows swipe actions with mouse
  });

  const currentReview = reviews[currentIndex];

  return (
    <div className="reviews-carousel" {...handlers}>
      <h2 className="reviews-headline">Guests Say</h2>

      <div className="reviews-cards">
        <div className={`review-card slide-up`}>
          <img className={`review-image pop-image`} src={currentReview.image} alt={currentReview.name} />
          <p className={`review-text`}>"{currentReview.text}"</p>
          <h4 className={`reviewer-name`}>- {currentReview.name}</h4>
        </div>
      </div>

      <button className="prev-button" onClick={handlePrevClick}>
        &lt;
      </button>

      <button className="next-button" onClick={handleNextClick}>
        &gt;
      </button>
    </div>
  );
};

export default ReviewsCarousel;

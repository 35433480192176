import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../styles/Home.css';
import Nav from '../components/Navbar';
import Ab from '../pages/About'; // Ensure you import the Reservation component
import OwnerMessage from './OwnerMessage';
import WelcomeSection from './Welcome';
import Reviews from './ReviewsCarousel';
import ContactPage from './ContactPage';
// import Specialities from './Specialities';
import Footer from './Footer';


function Home() {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleMenuClick = () => {
    navigate('/menu'); // Navigate to the menu page
  };

  return (
    <div>
      <div className="home">
        <Nav /> {/* Add the Navbar component here */}
        <div className="home-container">
          <h1 className="title">Welcome to</h1>
          <h2 className="main-title">The Mocktails</h2>
          <h3 className="subtitle">Cafe and Fine Dine</h3>
          <button className="mainbtn" onClick={handleMenuClick}>Look Menu</button> {/* Use button for navigation */}
        </div>
      </div>
      {/* <Reservation />  Use the Reservation component here */}
      <OwnerMessage/>
      <Ab />
      <WelcomeSection />
     {/**   <Specialities />*/}
      <Reviews />
      <ContactPage/>
      <Footer />
    </div>
  );
}

export default Home;

import React from 'react';
import '../styles/Navbar.css'; // Import the corresponding CSS file

function Navbar() {
  return (
    <nav className="navbar">
      <div className="logo">
        <a href="/" className="logo">
          <span className="main-logo">The Mocktails</span>
          <span className="sub-logo">Cafe and Fine Dine</span> {/* Add the subtitle */}
        </a>
      </div>
  {/*   <div className="nav-links"> 
        <a href="/">Home</a>
        <a href="/menu">Menu</a>
        <a href="/blogs">Blogs</a>
        <a href="/specialities">Specialities</a>
        <a href="/contact">Contact</a>
      </div>*/}
    </nav>
  );
}

export default Navbar;

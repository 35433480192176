import React, { useState } from 'react';
import '../styles/Footer.css';

function Footer() {
  // List of gallery images
  const galleryImages = [
    'assets/sp1.jpg',
    'assets/sp2.jpg',
    'assets/sp-3.jpg',
    'assets/sp-4.jpg',
    'assets/sp-5.jpg',
    'assets/sp-6.png',
 
  ];

  // State for managing modal visibility and selected image index
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Function to open modal with selected image index
  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsOpen(true);
  };

  // Function to close modal
  const closeModal = () => {
    setIsOpen(false);
  };

  // Function to go to the next image
  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % galleryImages.length);
  };

  // Function to go to the previous image
  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + galleryImages.length) % galleryImages.length);
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Section 1: Logo & About */}
        <div className="footer-section about">
          <h2 className="footer-logo">The Mocktails</h2>
          <p className="footer-description">
            Serving fine dining with refreshing drinks and a great atmosphere at The Mocktails. Visit us for a delightful experience.
          </p>
        </div>

        {/* Section 2: Contact Information */}
        <div className="footer-section contact-info">
          <h3>Contact Us</h3>
          <p> Gumtala Sub Urban, D - Block, Ranjit Avenue, Amritsar, Punjab 143001</p>
          <p>Phone: <a href="tel:+1234567890">+96 716 6879</a></p>
          <p>Email: <a href="mailto:contact@site.com">contact@site.com</a></p>
        </div>

        {/* Section 3: Latest Twitter 
        <div className="footer-section twitter-feed">
          <h3>Latest Twitter</h3>
          <p><a href="https://twitter.com">@colorlib</a></p>
          <p>Activelio is a good option. It has a slider...</p>
          <p>21 Dec 2017</p>
        </div>*/}

        {/* Section 4: Gallery */}
        <div className="footer-section gallery">
          <h3>Gallery</h3>
          <div className="gallery-images">
            {galleryImages.map((imgSrc, index) => (
              <img
                key={index}
                src={imgSrc}
                alt={`Gallery ${index + 1}`}
                onClick={() => openModal(index)}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Footer Bottom: Copyright */}
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} The Mocktails. All Rights Reserved.</p>
        <p className='myintro'>
          Design and Developed by <a href="https://abrarulhaq.netlify.app/" target="_blank" rel="noopener noreferrer">Abrar ul Haq</a>
        </p>
      </div>


      {/* Modal for fullscreen image */}
      {isOpen && (
        <div className="modal">
          <span className="close" onClick={closeModal}>&times;</span>
          <button className="prev" onClick={prevImage}>&#10094;</button>
          <img className="modal-content" src={galleryImages[currentImageIndex]} alt={`Gallery Full View ${currentImageIndex + 1}`} />
          <button className="next" onClick={nextImage}>&#10095;</button>
        </div>
      )}
    </footer>
  );
}

export default Footer;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCoffee, FaBeer, FaGlassMartini, FaLeaf, FaPizzaSlice, FaUtensils, FaBlender, FaGlassWhiskey, FaCocktail, FaCarrot, FaDrumstickBite, FaAppleAlt, FaBreadSlice } from 'react-icons/fa';
import '../styles/Menu.css';
import Nav from '../components/Navbar';
import '../styles/Footer.css';

const Menu = () => {
  const navigate = useNavigate(); // For redirecting to a new route
  const [searchTerm, setSearchTerm] = useState('');
 
  

  const categories = [
      { name: 'Hot Coffee', url: 'hot-coffee', icon: <FaCoffee /> },
      { name: 'Hot Tea', url: 'hot-tea', icon: <FaLeaf /> },
      { name: 'Ice Tea', url: 'ice-tea', icon: <FaGlassMartini /> },
      { name: 'Cold Coffee', url: 'cold-coffee', icon: <FaBeer /> },
      { name: 'Milkshake', url: 'milkshake', icon: <FaBlender /> },
      { name: 'Bobas Coffee', url: 'bobas-coffee', icon: <FaCoffee /> },
      { name: 'Smoothies', url: 'smoothies', icon: <FaGlassWhiskey /> },
      { name: 'Mocktail', url: 'mocktail', icon: <FaCocktail /> },
      { name: 'Veg Pizza', url: 'veg-pizza', icon: <FaPizzaSlice /> },
      { name: 'Non Veg Pizza', url: 'non-veg-pizza', icon: <FaPizzaSlice /> },
      { name: 'Garlic Bread', url: 'garlic-bread', icon: <FaBreadSlice /> },
      { name: 'Veg Sandwich', url: 'veg-sandwich', icon: <FaBreadSlice /> },
      { name: 'Non Veg Sandwich', url: 'non-veg-sandwich', icon: <FaBreadSlice /> }, // Using FaBreadSlice as a placeholder
      { name: 'Veg Burger', url: 'veg-burger', icon: <FaDrumstickBite /> }, // Using FaDrumstickBite as a placeholder
      { name: 'Non Veg Burger', url: 'non-veg-burger', icon: <FaDrumstickBite /> }, // Using FaDrumstickBite as a placeholder
      { name: 'Veg Snacks', url: 'veg-snacks', icon: <FaCarrot /> },
      { name: 'Non Veg Snacks', url: 'non-veg-snacks', icon: <FaDrumstickBite /> },
      { name: 'Veg Soup', url: 'veg-soup', icon: <FaAppleAlt /> },
      { name: 'Non Veg Soup', url: 'non-veg-soup', icon: <FaDrumstickBite /> },
      { name: 'Veg Rice & Biryani', url: 'veg-rice-biryani', icon: <FaUtensils /> }, // Using FaUtensils as a placeholder
      { name: 'Non Veg Rice & Biryani', url: 'non-veg-rice-biryani', icon: <FaUtensils /> }, // Using FaUtensils as a placeholder
      { name: 'Veg Tandoor', url: 'veg-tandoor', icon: <FaUtensils /> }, // Using FaUtensils as a placeholder
      { name: 'Non Veg Tandoor', url: 'non-veg-tandoor', icon: <FaUtensils /> }, // Using FaUtensils as a placeholder
  ];
  



  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchTerm)
  );

  return (
    <div className='MenuPage'>
      <Nav />
      <div className="menu-container">
        <h1 className="menu-title">Our Menu</h1>

        {/* Search Bar */}
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search categories..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>

        {/* Categories */}
        <div className="category-list">
          {filteredCategories.map((category) => (
            <div
              key={category.url}
              className="category-card"
              onClick={() => navigate(`/category/${category.url}`)} // Use URL-friendly names for routing
            >
              <div className="category-icon">{category.icon}</div>
              <h3 className="category-name">
                {category.name} {/* Show full names in UI */}
              </h3>
            </div>
          ))}
        </div>
      </div>
      <p className='myintro'>
          Design and Developed by <a href="https://abrarulhaq.netlify.app/" target="_blank" rel="noopener noreferrer">Abrar ul Haq</a>
        </p>
    </div>
  );
};

export default Menu;

// src/pages/ContactPage.js
import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import '../styles/ContactPage.css'; // Ensure you create this CSS file

const ContactPage = () => {
    const formRef = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_6a2iebl', 'template_yqkxq9a', formRef.current, 'TZQgE7Mah3rnBoJsm') // Replace 'YOUR_USER_ID' with your actual EmailJS user ID
            .then(
                (result) => {
                    alert('Email sent successfully!');
                    formRef.current.reset(); // Reset form after submission
                },
                (error) => {
                    alert('An error occurred. Please try again later.');
                    console.log(error.text); // Log the error for debugging
                }
            );
    };

    return (
        <div className="contact-page">
            <h1>Share your Feedback</h1>
            <h6>Your Feedback is Valuable to us</h6>
            <form ref={formRef} onSubmit={sendEmail} className="contact-form">
                <input type="text" name="from_name" placeholder="Your Name" required />
                <input type="email" name="from_email" placeholder="Your Email" required />
                <textarea name="message" placeholder="Your Message" required></textarea>
                <button type="submit">Send Message</button>
            </form>
            <div className="map-container">
                <h2>Visit Us</h2>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13584.469587054466!2d74.84321067998081!3d31.65800777232297!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391965000e63ddf1%3A0x210947f285b2e682!2sThe%20Mocktails!5e0!3m2!1sen!2sin!4v1729588063485!5m2!1sen!2sin"
                    width="600"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    title="Café Location"
                ></iframe>
            </div>
        </div>
    );
};

export default ContactPage;

import React from 'react';
import '../styles/OwnerMessage.css'; // Import your CSS file

const OwnerMessage = () => {
  return (
    <div className="owner-message-section">

      <div className="owner-content">

        <div className="owner-image-container">
          <img src="/assets/owner.png" alt="Cafe Owner" className="owner-image" /> {/* Update with actual image path */}
        </div>

        <div className="owner-message-container">
          <h2 className="owner-message-headline">Meet the Owners</h2> {/* Headline added */}
          <h3 className="owner-message-title">Our Vision and Passion</h3>
          <p className="owner-message-text">
            Welcome to <strong>The Mocktails Café</strong>, where every dish is made with passion and love.
            Our journey began with a simple vision: to create a space where friends and families can gather,
            relax, and enjoy delicious, hand-crafted meals. This café is more than just a place to dine;
            it's a reflection of our values, our commitment to quality, and our belief in the joy that
            comes from sharing good food with loved ones.
          </p>
          <p className="owner-message-text">
            Every ingredient is carefully selected, every recipe thoughtfully created. The café represents
            our dreams of building a community through food, where each bite tells a story of authenticity,
            freshness, and creativity. We hope that when you visit <strong>The Mocktails Café</strong>, you feel
            the warmth and care we put into every detail.
          </p>
          <p className="owner-message-text">
            Thank you for being a part of our journey. We look forward to welcoming you with open hearts and
            delicious dishes for years to come.
          </p>
          <p className="owner-signature">— The Café Owners</p>
        </div>


      </div>
    </div>
  );
};

export default OwnerMessage;

import React, { useState } from 'react';
import axios from 'axios';
import '../styles/Reservation.css'; // Ensure to import your styles for the reservation

function Reservation() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState('');
  const [guests, setGuests] = useState(1);
  const [message, setMessage] = useState(''); // For displaying success or error messages

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('http://localhost:5000/api/reservations', {
        name,
        email,
        date,
        guests,
      });
      setMessage(response.data.message); // Show success message
    } catch (error) {
      setMessage('Error creating reservation'); // Handle error
      console.error('Error:', error);
    }
  };

  return (
    <div className="reservation-section">
      <h2 className="reservation-title">Book a Reservation</h2>
      {message && <p className="message">{message}</p>} {/* Display message */}
      <form onSubmit={handleSubmit} className="reservation-form">
        <label>
          Name:
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
        </label>
        <label>
          Email:
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </label>
        <label>
          Date:
          <input type="date" value={date} onChange={(e) => setDate(e.target.value)} required />
        </label>
       
        <label>
          Number of Guests:
          <input type="number" value={guests} onChange={(e) => setGuests(e.target.value)} min="1" required />
        </label>
        <button type="submit" className="submit-button">Reserve Now</button>
      </form>
    </div>
  );
}

export default Reservation;

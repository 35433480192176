import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Menu from './pages/Menu';
import Reservation from './pages/Reservation'; 
import Category from './pages/Category'; 
import './index.css';

function App() {
  return (
    <Router>
     
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/reservation" element={<Reservation />} />
        <Route path="/category/:categoryName" element={<Category />} />
        <Route path="*" element={<div>404 Not Found</div>} /> {/* Fallback for unknown routes */}
      </Routes>
    </Router>
  );
}

export default App;

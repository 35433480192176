import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa'; // Import search icon
import '../styles/Category.css';

const menuCategories = {

  'hot-coffee': [
    { name: 'Espresso', price: '99/-' },
    { name: 'Americano', price: '109/-' },
    { name: 'Macchiato', price: '109/-' },
    { name: 'Flat White', price: '139/-' },
    { name: 'Affozato', price: '149/-' },
    { name: 'Cappuccino', price: '149/-' },
    { name: 'Cafe Mocha', price: '149/-' },
    { name: 'Cafe Vietnam', price: '149/-' },
    { name: 'Cafe Latte', price: '159/-' },
    { name: 'Hot Chocolate', price: '159/-' },
    { name: 'White Chocolate Mocha', price: '169/-' },
    { name: 'Dark Chocolate Mocha', price: '169/-' },
    { name: 'Green Mocha', price: '169/-' },
    { name: 'English Toffee', price: '169/-' },
    { name: 'Honey Rose Latte', price: '179/-' },
  ],
  'hot-tea': [
    { name: 'Green Tea', price: '99/-' },
    { name: 'Masala Tea', price: '109/-' },
    { name: 'Chamomile Tea', price: '119/-' },
    { name: 'Chai Latte', price: '139/-' },
  ],
  'ice-tea': [
    { name: 'Peach Ice Tea', price: '159/-' },
    { name: 'Lemon Ice Tea', price: '159/-' },
    { name: 'Passion Ice Tea', price: '169/-' },
    { name: 'Raspberry Ice Tea', price: '169/-' },
    { name: 'Berry Ginger', price: '179/' }
  ],

  'cold-coffee': [
    { name: 'Americano', price: '129/-' },
    { name: 'Iced Latte', price: '159/-' },
    { name: 'Iced Cappuccino', price: '169/-' },
    { name: 'Iced Mocha', price: '179/-' },
    { name: 'Frappe', price: '199/-' },
    { name: 'Frappe Mocha', price: '219/-' },
    { name: 'Nutrella Frappe', price: '229/-' },
    { name: 'Iced Chocolate', price: '229/-' },
    { name: 'Brownie Frappe', price: '249/-' },
    { name: 'Toffee Popcorn Latte', price: '249/-' },
    { name: 'Bipcoff Iced Latte', price: '249/-' },
    { name: 'Nutrella Iced Latte', price: '259/-' },
    { name: 'ADD ON FLAVOURS', description: 'Caramel, Hazelnut, Vanilla, Irish, Popcorn, French Vanilla, Butter Scotch, Toffee Nut', price: '' }
  ],

  'bobas-coffee': [
    { name: 'Classic Boba', price: '229/-' },
    { name: 'Mango Boba', price: '239/-' },
    { name: 'Matcha Boba', price: '249/-' },
    { name: 'Fruity Boba', price: '269/-' },
    { name: 'Cold Brew Boba', price: '289/-' }
  ],

  'smoothies': [
    { name: 'PB Smoothies', price: '259/-' },
    { name: 'Choco Chip Smoothies', price: '269/-' },
    { name: 'Mango Pineapple Smoothies', price: '279/-' },
    { name: 'Mixed Berry Smoothies', price: '299/-' }
  ],
'mocktail': [
  { name: 'Fresh Lemon Soda', price: '109/-' },
  { name: 'Virgin Mojito', price: '149/-' },
  { name: 'Raspberry Margarita', price: '189/-' },
  { name: 'Sunrise Blossom', price: '189/-' },
  { name: 'Masala Jamun', price: '199/-' },
  { name: 'Apple Elder Flower', price: '199/-' },
  { name: 'Summer Watermelon Lemonade', price: '199/-' },
  { name: 'Pina Colada', price: '209/-' },
  { name: 'Mocha Mojito', price: '209/-' },
  { name: 'Cafe Special Sangria', price: '249/-' }
],

  'veg-pizza': [
  { name: 'Cheese Margherita', price: '199/-' },
  { name: 'Simply Veg Pizza', price: '199/-' },
  { name: 'Mexican Choice', price: '199/-' },
  { name: 'Chilli Paneer', price: '199/-' },
  { name: 'Masala Paneer Makhani', price: '199/-' },
  { name: 'Veg Kohlapuri Pizza', price: '149/-' }
],
'non-veg-pizza': [
  { name: 'Cheese & BBQ Chicken Pizza', price: '239/-' },
  { name: 'Hot Chicken Pizza', price: '239/-' },
  { name: 'Chicken Dominator Pizza', price: '239/-' },
  { name: 'Chicken Farmhouse Pizza', price: '239/-' }
],

  'veg-pasta': [
  { name: 'Alfredo Pasta', price: '249/-' },
  { name: 'Arrabiata Pasta', price: '249/-' },
  { name: 'Garlic Pasta', price: '249/-' },
  { name: 'Tandoori Pasta', price: '249/-' },
  { name: 'Napoleon Pasta', price: '249/-' },
],
'non-veg-pasta': [
  { name: 'Creamolata Pasta', price: '299/-' },
  { name: 'Chicken Indian Pasta', price: '299/-' },
  { name: 'Tandoori Chicken Pasta', price: '299/-' },
  { name: 'Sweet Heat Pasta', price: '299/-' },
  { name: 'Craven Hot Pasta', price: '299/-' },
],

  'milkshake': [
    { name: 'Oreo Cookie Shake', price: '219/-' },
    { name: 'Kitkat Shake', price: '219/-' },
    { name: 'Chocolate Shake', price: '229/-' },
    { name: 'Strawberry Shake', price: '229/-' },
    { name: 'Mango Shake', price: '239/-' }, // Corrected typo: 'Mongo' to 'Mango'
    { name: 'Butterscotch Shake', price: '239/-' },
    { name: 'Blueberry Shake', price: '249/-' }, // Corrected spelling: 'Blue Berry' to 'Blueberry'
    { name: 'Dry Fruit Shake', price: '249/-' },
    { name: 'Pineapple Shake', price: '259/-' },
    { name: 'Brownie Shake', price: '259/-' },
    { name: 'Salted Caramel Shake', price: '269/-' },
    { name: 'Lotus Biscoff Shake', price: '269/-' }
  ],
  
  'garlic-bread': [
  { name: 'Roasted Garlic Bread', price: '149/-' },
  { name: 'Cheesy Garlic Bread', price: '149/-' },
  { name: 'Super Garlic Bread', price: '149/-' },
  { name: 'Garlic Bread Cheese Corn', price: '99/-' },
],

'veg-sandwich': [
  { name: 'Spicy Paneer Sandwich', price: '149/-' },
  { name: 'Veggie Grilled Sandwich', price: '149/-' },
  { name: 'Club Sandwich', price: '199/-' },
  { name: 'Our Special Sandwich', price: '149/-' },
  { name: 'Cheese Corn Sandwich', price: '149/-' },
],

'non-veg-sandwich': [
  { name: 'Chicken Delight Sandwich', price: '199/-' },
  { name: 'Italian Grill Sandwich', price: '199/-' },
  { name: 'Club Sandwich', price: '219/-' },
  { name: 'Our Special Sandwich', price: '' },
],

'veg-burger': [
  { name: 'Crunchy Paneer Burger', price: '119/-' },
  { name: 'Chilli Potato Burger', price: '119/-' },
  { name: 'Spicy Paneer Burger', price: '149/-' },
  { name: 'Veggie La Special Burger', price: '149/-' },
],

'non-veg-burger': [
  { name: 'Chicken Burger', price: '149/-' },
  { name: 'Spicy Grill Chicken Burger', price: '149/-' },
  { name: 'Crunchy Creamy Chicken Burger', price: '159/-' },
  { name: 'Our Special Chicken Burger', price: '159/-' },
],

'veg-wrap': [
  { name: 'Spicy Alu Tikki Wrap', price: '99/-' },
  { name: 'Veggie Mexican Salsa Wrap', price: '99/-' },
  { name: 'Hara Bhara Kebab Wrap', price: '99/-' },
  { name: 'Paneer Tikka Wrap', price: '119/-' },
],

'non-veg-wrap': [
  { name: 'Crunchy Chicken Wrap', price: '129/-' },
  { name: 'Mexican Salsa Chicken Wrap', price: '129/-' },
  { name: 'Chicken Tikka Wrap', price: '129/-' },
  { name: 'Chicken Chungs Wrap', price: '129/-' },
],

'veg-snacks': [
  { name: 'Cheese Corn Roll', price: '199/-' },
  { name: 'Mushroom Duplex', price: '249/-' },
  { name: 'Veg Enchiladas', price: '249/-' },
  { name: 'Mushroom Cheese Cigar Roll', price: '99/-' },
  { name: 'French Fries', price: '149/-' },
  { name: 'Spicy French Fries Cheese Fries', price: '149/-' },
],

'non-veg-snacks': [
  { name: 'BBQ Death Wings', price: '249/-' },
  { name: 'Crunchy Chicken', price: '249/-' },
  { name: 'KFC Chicken (6pcs)', price: '249/-' },
  { name: 'KFC Chicken (9pcs)', price: '299/-' },
  { name: 'Chicken Strips', price: '249/-' },
  { name: 'Chicken Nuggets', price: '199/-' },
],

'veg-soup': [
  { name: 'Hot & Sour', price: '149/-' },
  { name: 'Manchow Soup', price: '149/-' },
  { name: 'Sweet Corn Soup', price: '149/-' },
  { name: 'Talumein Soup', price: '149/-' },
  { name: 'Lemon Coriander Soup', price: '149/-' },
],

'non-veg-soup': [
  { name: 'Chicken Hot & Sour', price: '199/-' },
  { name: 'Chicken Manchow', price: '199/-' },
  { name: 'Talumein Soup', price: '199/-' },
],

'veg-starters': [
  { name: 'Oriental Cheese Chilli', price: '299/-' },
  { name: 'Manchurian', price: '249/-' },
  { name: 'Veg Crispy', price: '249/-' },
  { name: 'Honey Chilli Potato', price: '249/-' },
  { name: 'Fried Rice', price: '199/-' },
],

'non-veg-starters': [
  { name: 'Oriental Chilli Chicken', price: '299/-' },
  { name: 'Garlic Chicken', price: '299/-' },
  { name: 'Lemon Chicken', price: '299/-' },
  { name: 'Chicken Pakora', price: '249/-' },
  { name: 'Crispy Chicken', price: '299/-' },
],



'veg-noodles': [
  { name: 'Hakka Noodles', price: '249/-' },
  { name: 'Schezwan Noodles', price: '249/-' },
  { name: 'Singapuri Noodles', price: '249/-' },
],

'non-veg-noodles': [
  { name: 'Hakka Noodles', price: '299/-' },
  { name: 'Schezwan Noodles', price: '299/-' },
  { name: 'Singapuri Noodles', price: '299/-' },
],

'veg-indian-main-course': [
  { name: 'Dal Makhani', price: '299/-' },
  { name: 'Yellow Dal Tadka', price: '249/-' },
  { name: 'Mix Veg', price: '298/-' },
  { name: 'Paneer Butter Masala', price: '349/-' },
  { name: 'Mushroom Do Pyaza', price: '299/-' },
  { name: 'Paneer Lababdar', price: '349/-' },
  { name: 'Kadhai Paneer', price: '349/-' },
  { name: 'Paneer Kali Mirch', price: '349/-' },
  { name: 'Paneer Bhurji', price: '249/-' },
  { name: 'Mushroom Tikka Masala', price: '349/-' },
  { name: 'Malai Kofta', price: '299/-' },
],

'non-veg-indian-main-course': [
  { name: 'Butter Chicken', price: '299/-' },
  { name: 'Chicken Kali Mirch', price: '349/-' },
  { name: 'Chicken Tikka Masala', price: '399/-' },
  { name: 'Chicken Lababdar', price: '299/-' },
  { name: 'Chicken Curry', price: '349/-' },
  { name: 'Mutton Rogan Josh', price: '449/-' },
],

'veg-rice-biryani': [
  { name: 'Veg Biryani', price: '349/-' },
  { name: 'Plain Rice', price: '149/-' },
  { name: 'Jeera Rice', price: '199/-' },
],

'non-veg-rice-biryani': [
  { name: 'Chicken Biryani', price: '379/-' },
  { name: 'Mutton Biryani', price: '449/-' },
],

'veg-tandoor': [
  { name: 'Paneer Tikka', price: '299/-' },
  { name: 'Paneer Malai Tikka', price: '249/-' },
  { name: 'Mushroom Tikka', price: '299/-' },
  { name: 'Mushroom Stuffed Tikka', price: '329/-' },
  { name: 'Veg Seekh Kebab', price: '299/-' },
  { name: 'Paneer Hariyali Tikka', price: '349/-' },
  { name: 'Paneer Achari Tikka', price: '349/-' },
  { name: 'Veg Platter', price: '499/-' },
],

'non-veg-tandoor': [
  { name: 'Chicken Tikka', price: '349/-' },
  { name: 'Tandoori Chicken', price: '349/-' },
  { name: 'Chicken Malai Tikka', price: '299/-' },
  { name: 'Chicken Achari Tikka', price: '349/-' },
  { name: 'Chicken Seekh Kebab', price: '399/-' },
  { name: 'Chicken Haryali Tikka', price: '399/-' },
  { name: 'Non Veg Platter', price: '599/-' },
],

'mix-breads': [
  { name: 'Butter Naan', price: '59/-' },
  { name: 'Plain Naan', price: '49/-' },
  { name: 'Butter Roti', price: '35/-' },
  { name: 'Plain Roti', price: '29/-' },
  { name: 'Missi Roti', price: '59/-' },
  { name: 'Lacha Parantha', price: '79/-' },
  { name: 'Pudina Laccha Parantha', price: '89/-' },
  { name: 'Garlic Naan', price: '79/-' },
  { name: 'Cheese Naan With Gravy', price: '199/-' },
  { name: 'Keema Naan With Gravy', price: '299/-' },
],

'raita-salad': [
  { name: 'Russian Salad', price: '249/-' },
  { name: 'Green Salad', price: '99/-' },
  { name: 'Plain Curd', price: '99/-' },
  { name: 'Mix Raita', price: '149/-' },
  { name: 'Boondi Raita', price: '149/-' },
  { name: 'Peanut Masala', price: '129/-' },
  { name: 'Masala Papad', price: '99/-' },
],

'desserts': [
  { name: 'Brownie With Ice Cream', price: '' },
  { name: 'Red Velvet Cake', price: '239/-' },
  { name: 'Chocolate Cake', price: '249/-' },
  { name: 'Tuti Fruity Cake', price: '229/-' },
],

};

const Category = () => {
  const { categoryName } = useParams(); // Get category name from the URL
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate(); // Use navigate for the back button

  const goBack = () => {
    navigate(-1); // Go back to the previous page
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  // Filter items based on the search term
  const filteredItems = menuCategories[categoryName]?.filter((item) =>
    item.name.toLowerCase().includes(searchTerm)
  ) || []; // Fallback to empty array if category doesn't exist

  return (
    <div className="category-page">
      <button className="back-button" onClick={goBack}>
        &larr; Back
      </button>

      <div className="category-header">
        <h2 className="category-title">
          {categoryName.charAt(0).toUpperCase() + categoryName.slice(1).replace('-', ' ')} Items
        </h2>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search items..."
            value={searchTerm}
            onChange={handleSearch}
          />
          <button className="search-button">
            <FaSearch />
          </button>
        </div>
      </div>

      <ul className="menu-list">
        {filteredItems.map((item, index) => (
          <li key={index} className="menu-item">
            <div className="item-info">
              <h3 className="item-name">{item.name}</h3>
              <p className="item-description">{item.description}</p>
            </div>
            <span className="item-price">{item.price}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Category;
